import { ResponseType, ErrorType } from '@zola-helpers/client/dist/es/http/types';
import { getCookie } from '@zola-helpers/client/dist/es/util/storage';

import _includes from 'lodash/includes';
import _isPlainObject from 'lodash/isPlainObject';
import _pickBy from 'lodash/pickBy';

import CognitoAuth from './cognitoAuth';
import { getCognitoConfig } from './cognitoConfig';
import { alphabetical } from './sortUtils';

const CSRF_TOKEN_HEADER = 'x-csrf-token';
const CSRF_TOKEN_COOKIE = 'CSRF-TOKEN';

const cognitoConfig = getCognitoConfig();
const cognitoAuth = new CognitoAuth(cognitoConfig);

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

interface CsrfHeaders {
  [CSRF_TOKEN_HEADER]?: string;
}

interface CognitoHeaders {
  'Cognito-Id-Token'?: string;
  'Cognito-Refresh-Token'?: string;
}

const getAbsoluteUrl = (url: string): string => {
  let base = 'http://localhost';
  if (typeof window !== 'undefined') {
    base = `${window.location.protocol}//${window.location.host}`;
  }
  return new URL(url, base).toString();
};

const isJsonContent = (headers: HeadersInit): boolean => {
  return (headers as Record<string, string>)['Content-Type'] === 'application/json';
};

const getCsrfHeaders = (): CsrfHeaders => ({
  [CSRF_TOKEN_HEADER]: getCookie(CSRF_TOKEN_COOKIE),
});

const getCognitoHeaders = (): CognitoHeaders => {
  const cognitoUser = cognitoAuth.getCurrentUserLocalData();
  if (!cognitoUser) return {};
  // @ts-expect-error These properties are there!
  const { username, keyPrefix, storage } = cognitoUser;
  const idTokenKey = `${keyPrefix}.${username}.idToken`;
  const refreshTokenKey = `${keyPrefix}.${username}.refreshToken`;
  const idToken = storage[idTokenKey];
  const refreshToken = storage[refreshTokenKey];
  return {
    'Cognito-Id-Token': idToken,
    'Cognito-Refresh-Token': refreshToken,
  };
};

export const getRequestHeaders = (method: HttpMethod, headers: HeadersInit): HeadersInit => {
  const cognitoHeaders = getCognitoHeaders();
  const csrfHeaders = method !== HttpMethod.GET ? getCsrfHeaders() : undefined;
  const requestHeaders = {
    'Content-Type': 'application/json',
    ...cognitoHeaders,
    ...csrfHeaders,
    ...headers,
  };
  return _pickBy(requestHeaders, (value) => value !== undefined) as HeadersInit;
};

export const getRequestOptions = (
  method: HttpMethod,
  body: unknown | null,
  options: RequestInit,
  headers: HeadersInit
): RequestInit => {
  const requestHeaders = getRequestHeaders(method, headers);
  return {
    method,
    body: body && isJsonContent(requestHeaders) ? JSON.stringify(body) : (body as BodyInit),
    headers: requestHeaders,
    credentials: 'same-origin' as RequestCredentials,
    ...options,
  };
};

const handlePromise = (response: ResponseType): Promise<[boolean, unknown]> => {
  const contentType = response.headers && response.headers.get('Content-Type');
  if (_includes(contentType, 'application/json')) {
    return Promise.all([response.ok, response.json()]);
  }
  return Promise.all([response.ok, response.text()]);
};

export const handleErrors = ([ok, unknownResponse]: [boolean, unknown]): unknown => {
  const response = unknownResponse as ResponseType;
  if (!ok) {
    const errorMessage = response?.error?.message || 'An error has occurred';
    const error: ErrorType<ResponseType> = new Error(errorMessage);
    error.response = response;
    throw error;
  }
  return response;
};

const request = (url: string, options: RequestInit): Promise<any> => {
  return fetch(getAbsoluteUrl(url), options).then(handlePromise).then(handleErrors);
};

type CacheKey = any[];
interface Options extends RequestInit {
  cacheKey?: CacheKey;
}

/**
 * Caches the request promises so that if multiple requests are made with the same cache key, they will all resolve to the same value.
 * If the request fails, the cache key is invalidated, so a new request will be made on the next call.
 */
const createCache = () => {
  const cacheMap = new Map();

  return {
    set: <T extends Promise<any>>(
      url: string,
      cacheKey: CacheKey | undefined,
      createRequest: () => T
    ): T => {
      if (!cacheKey) return createRequest();
      const key = stableValueHash([url, ...cacheKey]);
      if (cacheMap.has(key)) return cacheMap.get(key);
      const promise = createRequest();
      cacheMap.set(key, promise);

      // If the request fails, remove the cache so it can be requested again. This does not do any retries.
      promise.catch(() => cacheMap.delete(key));

      return promise;
    },
    clear: () => cacheMap.clear(),
    get: cacheMap.get,
  };
};

/**
 * Hashes the value into a stable hash. From React Query: https://github.com/tannerlinsley/react-query/blob/e7a3207f74b27bc112d6ab583831cceac60f94c5/src/core/utils.ts#L301
 */
function stableValueHash(value: any): string {
  return JSON.stringify(value, (_, val) =>
    _isPlainObject(val)
      ? Object.keys(val)
          .sort(alphabetical)
          .reduce((result, key) => {
            // eslint-disable-next-line no-param-reassign
            result[key] = val[key];
            return result;
          }, {} as any)
      : val
  );
}

const getCache = createCache();

// TODO: The promise completion type should be `unknown` when we're ready to start enforcing types
const get = async <ResponseType = any>(
  url: string,
  options: Options = {},
  headers: HeadersInit = {}
): Promise<ResponseType> => {
  const requestOptions = getRequestOptions(HttpMethod.GET, null, options, headers);
  return getCache.set(url, options.cacheKey, () => request(url, requestOptions));
};

const postCache = createCache();

// TODO: The promise completion type should be `unknown` when we're ready to start enforcing types
const post = async <ResponseType = any, BodyType = Record<string, unknown>>(
  url: string,
  body: BodyType | null = null,
  options: Options = {},
  headers: HeadersInit = {}
): Promise<ResponseType> => {
  const requestOptions = getRequestOptions(HttpMethod.POST, body, options, headers);
  return postCache.set(url, options.cacheKey, () => request(url, requestOptions));
};

// TODO: The promise completion type should be `unknown` when we're ready to start enforcing types
const put = <ResponseType = any, BodyType = Record<string, unknown>>(
  url: string,
  body: BodyType | null = null,
  options: RequestInit = {},
  headers: HeadersInit = {}
): Promise<ResponseType> => {
  const requestOptions = getRequestOptions(HttpMethod.PUT, body, options, headers);
  return request(url, requestOptions);
};

// TODO: The promise completion type should be `unknown` when we're ready to start enforcing types
const deleteMethod = <ResponseType = any>(
  url: string,
  options: RequestInit = {},
  headers: HeadersInit = {}
): Promise<ResponseType> => {
  const requestOptions = getRequestOptions(HttpMethod.DELETE, null, options, headers);
  return request(url, requestOptions);
};

const ApiService = {
  type: 'client',
  request,
  get,
  post,
  put,
  delete: deleteMethod,
  getCognitoHeaders,
  clearCache: () => {
    getCache.clear();
    postCache.clear();
  },
};

export default ApiService;

export type ClientApiServiceType = typeof ApiService;
