import getEnvironment from '@zola-helpers/client/dist/es/util/environment';

export interface CognitoConfig {
  userPoolId: string;
  clientId: string;
}

const productionCognitoConfig: CognitoConfig = {
  userPoolId: 'us-east-1_00fjRM7NI',
  clientId: '3udbe37q01kug3ll1revb64qcv',
};

const stagingCognitoConfig: CognitoConfig = {
  userPoolId: 'us-east-1_juW6CqK8F',
  clientId: 'oqic1ndmn30elcm95mvqa7l15',
};

const developmentCognitoConfig: CognitoConfig = {
  userPoolId: 'us-east-1_PnY94QjC8',
  clientId: '6u9kpduquu4iu5uifq7jhu06ka',
};

export const getCognitoConfig = (): CognitoConfig => {
  switch (getEnvironment()) {
    case 'production':
      return productionCognitoConfig;
    case 'staging':
      return stagingCognitoConfig;
    default:
      return process.env.NEXT_PUBLIC_ENV === 'devstaging'
        ? stagingCognitoConfig
        : developmentCognitoConfig;
  }
};
