type ObjectWithKey = { key: string };

/**
 * Compare function to sort strings in alphabetical order
 */
export const alphabetical = (a: string, b: string) => a.localeCompare(b);

/**
 * Compare function to sort strings in alphabetical order
 */
export const reverseAlphabetical = (a: string, b: string) => b.localeCompare(a);

/**
 * Compare function to sort numbers in numerical order
 */
export const numerical = (a: number, b: number) => a - b;

/**
 * Compare function to sort objects in alphabetical order by key
 */
export const alphabeticalByKey = (a: ObjectWithKey, b: ObjectWithKey) => a.key.localeCompare(b.key);
