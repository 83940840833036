/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';

import { CognitoConfig } from './cognitoConfig';

export interface SignUpResult {
  id: string;
  email: string;
}

export interface LoginResult {
  idToken: string;
  idTokenExpiresAt: string;
  accessToken: string;
  refreshToken: string;
  id: string;
  email: string;
}

class CognitoAuth {
  userPool: CognitoUserPool;

  constructor(cognitoConfig: CognitoConfig) {
    const { userPoolId, clientId } = cognitoConfig;
    this.userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    });
  }

  signup(email: string, password: string): Promise<SignUpResult> {
    return new Promise((resolve, reject) => {
      const userAttributes: CognitoUserAttribute[] = [];
      const validationData: CognitoUserAttribute[] = [];
      const emailData = {
        Name: 'email',
        Value: email,
      };
      const emailAttribute = new CognitoUserAttribute(emailData);
      userAttributes.push(emailAttribute);
      this.userPool.signUp(email, password, userAttributes, validationData, (error, result) => {
        if (error) {
          reject(error);
          return;
        }
        if (!result || !result.user) {
          reject(new Error('User is not signed up.')); // This shouldn't happen if there's no error
          return;
        }
        const { user, userSub } = result;
        const username = user.getUsername();
        resolve({
          id: userSub,
          email: username,
        });
      });
    });
  }

  confirmRegistration(email: string, code: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: this.userPool,
      });
      cognitoUser.confirmRegistration(code, true, (error, _result) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(); // The result is just "SUCCESS"
      });
    });
  }

  resendConfirmationCode(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: this.userPool,
      });
      cognitoUser.resendConfirmationCode((error, _result) => {
        if (error) {
          reject(error);
          return;
        }
        resolve();
      });
    });
  }

  login(email: string, password: string, newPassword: string | null = null): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      const handleSuccess = (result: CognitoUserSession) => {
        const idToken = result.getIdToken();
        const accessToken = result.getAccessToken();
        const refreshToken = result.getRefreshToken();
        const { sub: payloadSub, exp: payloadExp, email: payloadEmail } = idToken.payload;
        resolve({
          idToken: idToken.getJwtToken(),
          idTokenExpiresAt: payloadExp,
          accessToken: accessToken.getJwtToken(),
          refreshToken: refreshToken.getToken(),
          id: payloadSub,
          email: payloadEmail,
        });
      };
      // The Cognito IAuthenticationCallback defines the error param as `any`
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handleFailure = (error: any) => {
        reject(error);
      };
      const authenticationData = {
        Username: email,
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const cognitoUser = new CognitoUser({
        Username: authenticationData.Username,
        Pool: this.userPool,
      });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: handleSuccess,
        onFailure: handleFailure,
        newPasswordRequired: (_userAttributes, _requiredAttributes) => {
          if (newPassword) {
            const requiredAttributeData = { email };
            cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributeData, {
              onSuccess: handleSuccess,
              onFailure: handleFailure,
            });
          } else {
            // Handle forcing a password change the same way we handle unconfirmed users
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              code: 'ForceChangePasswordException',
              name: 'ForceChangePasswordException',
              message: 'User is required to change their password.',
            });
          }
        },
      });
    });
  }

  changePassword(currentPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) {
        // The change-password API call requires a valid session
        cognitoUser.getSession(
          (sessionError: Error | null, _session: CognitoUserSession | null) => {
            if (sessionError) {
              reject(sessionError);
              return;
            }
            cognitoUser.changePassword(currentPassword, newPassword, (error, _result) => {
              if (error) {
                reject(error);
                return;
              }
              resolve(); // The result is just "SUCCESS"
            });
          }
        );
      } else {
        reject(new Error('User is not authenticated.'));
      }
    });
  }

  sendPasswordResetCode(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: this.userPool,
      });
      cognitoUser.forgotPassword({
        // The data parameter contains code delivery details (attribute, medium, destination)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSuccess: (_data) => {
          resolve();
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });
  }

  confirmPassword(email: string, code: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: this.userPool,
      });
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });
  }

  getUserAttributeValue(userAttributes: ICognitoUserAttributeData[], name: string): string | null {
    const userAttribute = userAttributes.filter((attribute) => attribute.Name === name)[0] || {};
    const { Value: value = null } = userAttribute;
    return value;
  }

  getCurrentUser(): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((sessionError: Error | null, session: CognitoUserSession) => {
          if (sessionError) {
            reject(sessionError);
            return;
          }
          // Calling this and bypassing the cache forces an API call which validates the session
          cognitoUser.getUserData(
            (userDataError, userData) => {
              if (userDataError) {
                reject(userDataError);
                return;
              }
              const idToken = session.getIdToken();
              const accessToken = session.getAccessToken();
              const refreshToken = session.getRefreshToken();
              const { sub: payloadSub, exp: payloadExp, email: payloadEmail } = idToken.payload;
              const { UserAttributes: userAttributes = [] } = userData || {};
              const id = this.getUserAttributeValue(userAttributes, 'sub') || payloadSub;
              const email = this.getUserAttributeValue(userAttributes, 'email') || payloadEmail;
              resolve({
                idToken: idToken.getJwtToken(),
                idTokenExpiresAt: payloadExp,
                accessToken: accessToken.getJwtToken(),
                refreshToken: refreshToken.getToken(),
                id,
                email,
              });
            },
            // @ts-ignore This is correct based on the documentation at https://www.npmjs.com/package/amazon-cognito-identity-js
            { bypassCache: true }
          );
        });
      } else {
        reject(); // This is an expected failure when no user is logged in
      }
    });
  }

  logout(): Promise<void> {
    return new Promise((resolve) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) cognitoUser.signOut();
      resolve();
    });
  }

  getCurrentUserLocalData(): CognitoUser | null {
    return this.userPool.getCurrentUser();
  }
}

export default CognitoAuth;
